import clsx from "clsx";
import {useEffect, useState} from 'react'
import { Modal } from "react-bootstrap";
import { Button, Checkbox, Select } from "..";
import "./change-tonal.css";
import "bootstrap/dist/css/bootstrap.min.css";
import moment from "moment";
import { ApiServices } from "../../services/api-services";
import { DeleteOutlined } from "@ant-design/icons";

type Props = {
  ownerId: any;
  networkId: any;
  postIsManual?: boolean;
  postId: string;
  className?: string;
  referenceId?: string;
  trust: number;
  tonal?: string;
  tonalFilter?: any;
  onTonalChange: (value: number) => void
  children?: any
};

export const ChangeTonal = (props: Props) => {
  const [show, setShow] = useState(false);
  const [isAllPost, setIsAllPost] = useState(false);
  const [tonal, setTonal] = useState(props.trust)
  const [tonalChanged, setTonalChanged] = useState(false)

  useEffect(() => {
    if (tonal === props.trust && tonalChanged) {
      setTonalChanged(false)
      return
    }
    setTonalChanged(true)
  }, [tonal])

  const handleOk = (): void => {
    sendData();
  };

  const sendData = async () => {
    const params = {
      post_id: props.postId,
      owner_id: props.ownerId,
      network_id: props.networkId,
      trust: tonal,
      is_manual: props.postIsManual
    }

    await ApiServices.setPostTrust(params);
    props.onTonalChange(tonal)
    handleClose()
  };

  const handleClose = () => {
    setShow(false);
    setIsAllPost(false)
  }

  const handleShow = () => setShow(true);

  return (
    <div>
      {/*<Button className={props.className} onClick={handleShow}>*/}
      {/*  Изменить тональность*/}
      {/*</Button>*/}
      <div className="post-menu__item" onClick={handleShow}>
        {props.children}
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Изменение тональности</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="change-post-tonal-modal">
            <div className="change-post-tonal-modal__wrap">
              <div className="change-post-tonal-modal__list">
                <div
                  className={`change-post-tonal-modal__item positive${tonal === 1 ? ' active' : ''}`}
                  onClick={() => setTonal(1)}
                >
                  Положительная
                </div>
                <div
                  className={`change-post-tonal-modal__item${tonal === 0 ? ' active' : ''}`}
                  onClick={() => setTonal(0)}
                >
                  Нейтральная
                </div>
                <div
                  className={`change-post-tonal-modal__item negative${tonal === -1 ? ' active' : ''}`}
                  onClick={() => setTonal(-1)}
                >
                  Негативная
                </div>
              </div>
              {/*<div*/}
              {/*  className="change-post-tonal-modal__all"*/}
              {/*  onClick={() => setIsAllPost(prevState => !prevState)}*/}
              {/*>*/}
              {/*  <div className={`checkbox${isAllPost ? ' active' : ''}`}/><span>Для всех публикаций</span>*/}
              {/*</div>*/}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose}>Отменить</Button>

          <Button onClick={handleOk}>
            Отправить
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
