import clsx from "clsx";
import { ChangeEvent, SetStateAction, useEffect, useState } from "react";
import {
  useThreadsQuery,
  useTopicsQuery,
  useRefSourcesQuery,
  useSmiQuery,
  mergeQueries,
  useMainTopicQuery,
  useStatsQuery,
} from "../../features";
import { useAppSelector } from "../../redux";
import {
  Button,
  PageTitle,
  Panel,
  ApiInfo,
  NoData,
  Topic,
  Field,
  Select,
  DateRangePicker,
  Checkbox,
} from "../../ui";
import s from "./topics.module.css";
import CustomSelect from "../../ui/custom-select/custom-select";
import moment from "moment";
import { ApiServices } from "../../services/api-services";
import {getFilteredData} from './utils/get-filtered-data'
import {DatePicker} from 'antd'
import locale from 'antd/es/date-picker/locale/ru_RU'
const { RangePicker } = DatePicker

export const Topics = () => {
  type StateType = "subject" | "event" | "smi" | "main";
  type PeriodType = "day" | "week" | "month" | "custom";
  const [type, setType] = useState<StateType>("main");
  const [period, setPeriod] = useState<PeriodType>("day");
  const [searchString, setSearchString] = useState("");
  const [onlyFederal, setOnlyFederal] = useState(false);
  const [items, setItems] = useState<Array<any>>([])
  const [allItems, setAllItems] = useState<any>({
    main: { items: [], isLoading: true },
    smi: { items: [], isLoading: true },
    event: { items: [], isLoading: true },
    subject: { items: [], isLoading: true },
  })
  const [detailedItems, setDetailedItems] = useState<any>({
    main: [],
    smi: [],
    event: [],
    subject: []
  })
  const [filteredData, setFilteredData] = useState([] as any[]);
  const [sourcesList, setSourcesList] = useState([] as any[]);
  const [sourcesListIsLoading, setSourcesListIsLoading] = useState(false);

  const [filterInProgress, setFilterInProgress] = useState(false);
  const [dataIsLoading, setDataIsLoading] = useState(false)

  const [precached, setPrecached] = useState<boolean>(true);
  const [currentSourceId, setCurrentSourceId] = useState<string>("");
  const [sourse, setSourse] = useState<string>("");
  const myArray = {id : 'all', keyword : 'Любой'}
  const [listSourse, setListSourse] = useState([]);
  const [profileID, setProfileID] = useState<string>("");
  const [networkID, setNetworkID] = useState<string>("");
  const [userFilter, setUserFilter] = useState<{ network_id: number; profile_id: string } | null>(null);

  const { REACT_APP_EVENTS_ID, REACT_APP_SUBJECTS_ID, REACT_APP_THREAD_ID } = process.env;

  const thread_id = +REACT_APP_THREAD_ID!;
  const reference_id = type === "event" ? REACT_APP_EVENTS_ID : REACT_APP_SUBJECTS_ID;

  const user_id = useAppSelector((state) => state.auth.userId);
  const [referenceIds, setReferenceIds] = useState<string | number>("");
  const statsQuery = useStatsQuery("");
  const [typeId, setTypeId] = useState<string>(process.env.REACT_APP_SUBJECTS_ID as string);
  const findType = (item: any) => item.id === +typeId;
  const activeTypeItems = statsQuery.data?.find(findType)?.items ?? [];
  const [customPeriod, setCustomPeriod] = useState<{
    from: string;
    to: string;
  }>({
    from: moment(new Date()).format('DD-MM-YYYY') + ' 00:00:00',
    to: moment(new Date()).format('DD-MM-YYYY') + ' 23:59:59',
  });

  // I have no idea why, but we have to pass all of this
  // to the api (even if it works without)
  const wtfHardCodedParams = {
    reference_item_id: 0,
    user_id,
    from:
      period === "custom" && customPeriod.from
        ? customPeriod.from
        : undefined,
    to:
      period === "custom" && customPeriod.to
        ? customPeriod.to
        : undefined,
    user_filter: userFilter,
  };

  useEffect(() => {
    if (type === "main") {
      if (referenceIds) {
        getAllMainData();
      } else {
        setAllItems((prevState: any) => ({ ...prevState, main: { items: [], isLoading: true }}))
      }
    }
    if (type === "smi") getAllSmiData();
    if (type !== "main" && type !== 'smi') getAllTopicsData();
  }, [type, referenceIds, period, customPeriod]);

  const getAllMainData = async () => {
    setDataIsLoading(true)
    setAllItems((prevState: any) => ({ ...prevState, main: { items: [], isLoading: true }}))
    const baseMainParams = {
      start: 0,
      limit: 20,
      thread_id: String(thread_id),
      period: period !== "custom" ? period : "",
      referenceFilter: referenceIds ? [referenceIds] : [],
      ...wtfHardCodedParams,
    }

    const mainSmiTopics = await ApiServices.getMainTopics({ ...baseMainParams, type: 'smi' });
    const mainSocialTopics = await ApiServices.getMainTopics({ ...baseMainParams, type: 'social' });

    let mainTopicList: any = [];
    if (mainSmiTopics.data?.items) {
      mainSmiTopics?.data?.items?.map((smiTopic: any) => {
        mainTopicList.push({
          ...smiTopic,
        });
      });
    }
    if (mainSocialTopics.data?.items) {
      mainSocialTopics?.data?.items?.map((socialTopic: any) => {
        mainTopicList.push({
          ...socialTopic,
        });
      });
    }

    setAllItems((prevState: any) => ({ ...prevState, main: { items: mainTopicList, isLoading: false }}))
    if (mainTopicList.length === 0) {
      setDataIsLoading(false)
    }
  };

  const getAllSmiData = async () => {
    setDataIsLoading(true)
    setAllItems((prevState: any) => ({ ...prevState, smi: { items: [], isLoading: true }}))
    const itemsResponse = await ApiServices.getCurrentSmi()

    if (itemsResponse?.data) {
      setAllItems((prevState: any) => ({ ...prevState, smi: { items: itemsResponse.data, isLoading: false }}))
      if (itemsResponse?.data.length === 0) {
        setDataIsLoading(false)
      }
    }
  }

  const getAllTopicsData = async () => {
    setDataIsLoading(true)
    setAllItems((prevState: any) => ({ ...prevState, [type]: { items: [], isLoading: true }}))
    const topicsParams = {
      type: "smi",
      start: 0,
      limit: 100,
      thread_id,
      reference_id,
      period: period !== "custom" ? period : "",
      precached,
      ...wtfHardCodedParams,
    };

    const itemsResponse = await ApiServices.getSourceTopic(topicsParams)

    if (itemsResponse?.data) {
      setAllItems((prevState: any) => ({ ...prevState, [type]: { items: itemsResponse.data.items, isLoading: false }}))
      if (itemsResponse?.data.items.length === 0) {
        setDataIsLoading(false)
      }
    }
  }

  const changeSourse = (value: any) => {
    if (value !== "") {
      if (value === 'all') {
        setSourse('');
        setProfileID('');
      } else {
        setSourse(value);
        setProfileID(value);
        setNetworkID(value.split(",")[2]);
      }
    }
  };

  const typeHandle = (value: StateType) => {
    setType(value);
    setProfileID("");
    setSourse("");
    setPeriod('day')
  }

  useEffect(() => {
    if (currentSourceId) {
      const currentSource = sourcesList.find(
        (it: any) => it.inner_id === currentSourceId
      );
      if (currentSource) {
        setUserFilter({
          network_id: currentSource.network_id,
          profile_id: currentSource.inner_id,
        });
      }
    } else {
      setUserFilter(null);
    }
  }, [currentSourceId]);

  useEffect(() => {
    setFilterInProgress(true)
    if (detailedItems[type]) {
      setFilteredData(getFilteredData(detailedItems[type], searchString, onlyFederal, sourse));
    }
    setFilterInProgress(false)
  }, [onlyFederal, detailedItems, searchString, sourse]);

  useEffect(() => {
    setListSourse([])
    if (allItems[type].items.length > 0) {
      getAllTopicsItems()
    } else {
      setDetailedItems((prevState: any) => ({ ...prevState, [type]: [] }))
    }
  }, [allItems.main, allItems.smi, allItems.subject, allItems.event])

  useEffect(() => {
    // if (activeTypeItems && activeTypeItems?.length > 0) {
    //   if (typeId === process.env.REACT_APP_SUBJECTS_ID || typeId === process.env.REACT_APP_EVENTS_ID) {
    //     setReferenceIds(activeTypeItems[0].id);
    //   } else {
    //     setReferenceIds("");
    //   }
    // }
    // if (type === 'main') {
      setReferenceIds("");
    // } else {
    //   setReferenceIds(activeTypeItems)
    // }
  }, [typeId, activeTypeItems]);

  const getAllTopicsItems = async () => {
    const itemsPromises: any[] = []
    for (const item of allItems[type].items) {
      itemsPromises.push(ApiServices.getTopic({
        id: item.id?.length > 10 ? item.id : item.group_id,
        type: item.url === '' ? 'social' : 'smi',
        referenceFilter: [parseInt(reference_id!)],
      }))
    }

    const tempItems: any[] = []
    Promise.all(itemsPromises).then(result => {
      result.forEach((it, idx) => {
        if (it.data) {
          const isFederal = it.data.items.filter((it: any) => it.smi_type === 'federal').length > 0
          tempItems.push(({
            ...allItems[type].items[idx],
            isFederal,
            details: it.data
          }))
        }
      })

      if (type === 'main') {
        let listOfSourse: any = [myArray];
        tempItems.map((item: any) => {
          const sources = item.details.items.map((it: { from_id: any; author: any; }) => ({
              id: it.from_id.toString(),
              keyword: it.author,
            })
          );
          sources.map((source: any) => {
            if (!listOfSourse.find((it: { id: any }) => it.id == source.id)) {
              listOfSourse.push(source)
            }
          })
        })

        setListSourse(listOfSourse)
      }

      setDetailedItems((prevState: any) => ({ ...prevState, [type]: tempItems}))
    }).finally(() => {
      setDataIsLoading(false)
    })
  }

  const searchChangeHandle = (evt: ChangeEvent<HTMLInputElement>) => {
    setSearchString(evt.target.value);
  };

  const newPeriod = (value: PeriodType) => {
    setPeriod(value);
    if (value !== "custom") setPrecached(true);
    else setPrecached(false);
  };

  const federalHandle = () => {
    setOnlyFederal((prevState) => !prevState);
  };

  const referenceIdsHandle = (value: string | number): void => {
    setReferenceIds(value);
  };

  const typeIdHandle = (value: string | number): void => {
    setTypeId(String(value));
  };

  const disabledDate = (current: any): boolean => {
    return moment(current).isAfter(moment(new Date(), 'DD-MM-YYYY'));
  }

  const onPeriodChange = (value: any, dateString: string[]): void => {
    setCustomPeriod({ from: dateString[0] + ' 00:00:00', to: dateString[1] + ' 23:59:59' })
  }

  return (
    <>
      <PageTitle>Темы дня: СМИ и Соц сети</PageTitle>
      <Panel className={s.filter} padding>
        <div className={s.filterLine}>
          <Field
            placeholder="Поиск"
            icon="search"
            className={s.search}
            onChange={searchChangeHandle}
          />
          <Button
            className={s.tab}
            active={type === "main"}
            onClick={() => typeHandle('main')}
          >
            Список тем
          </Button>
          <div className={clsx(s.resonans, s[type])} onClick={() => typeHandle('subject')}>
            <div className={s.restheme}>Резонансные темы</div>
            <div className={s.checkboxes}>
              <label htmlFor="subject" className={s.expandLabel}>
                Cубъекты
              </label>
              <Checkbox
                id="subject"
                className={s.tab}
                checked={type === "subject"}
                onChange={() => typeHandle('subject')}
              />
              {'/'}
              <label htmlFor="event" className={s.expandLabel}>
                Cобытия
              </label>
              <Checkbox
                id="event"
                className={s.tab}
                checked={type === "event"}
                onChange={() => typeHandle('event')}
              />
            </div>
          </div>
          <Button
            className={s.tab}
            active={type === "smi"}
            onClick={() => typeHandle('smi')}
          >
            Новости СПБ
          </Button>
          <Button
            className={s.tab}
            active={onlyFederal}
            onClick={() =>setOnlyFederal((prevState) => !prevState)}
          >
            Федеральная повестка
          </Button>
        </div>
        <div className={s.filterLine}>
          {type !== "smi" &&
            (
              <>
                <label className={s.range}>
                  <span className={s.label}>Период</span>
                  <CustomSelect
                    items={[
                      { id: "day", keyword: "День" },
                      { id: "week", keyword: "Неделя" },
                      { id: "month", keyword: "Месяц" },
                      { id: "custom", keyword: "Период" },
                    ]}
                    onChange={(value) => newPeriod(value as PeriodType)}
                    value={period}
                    type="interval"
                  />
                </label>

                {period === "custom" ? (
                  <div className={s.customRange}>
                    <RangePicker
                      value={[moment(customPeriod.from, 'DD-MM-YYYY HH-mm-SS'), moment(customPeriod.to, 'DD-MM-YYYY HH-mm-SS')]}
                      format={'DD-MM-YYYY'}
                      disabledDate={disabledDate}
                      onChange={onPeriodChange}
                      style={{ width: '240px', borderRadius: '10px' }}
                      allowClear={false}
                      locale={locale}
                    />
                  </div>
                ) : null}
              </>
            )}
          {type === "main" && (
            <>
              <label className={s.range}>
                <span className={s.label}>Тип:</span>
                <CustomSelect
                  items={[
                    {
                      id: process.env.REACT_APP_SUBJECTS_ID!,
                      keyword: "Субъект",
                    },
                    {
                      id: process.env.REACT_APP_EVENTS_ID!,
                      keyword: "Событие",
                    },
                  ]}
                  onChange={typeIdHandle}
                  value={typeId}
                  isWide={true}
                />
              </label>
              {typeId == "4" ? (
                <label className={s.rangeWide}>
                  <span className={s.label}>Событие:</span>
                  <CustomSelect
                    items={activeTypeItems}
                    onChange={referenceIdsHandle}
                    value={referenceIds}
                    isLoading={statsQuery.isLoading}
                    isWide={true}
                    withSearch={true}
                    placeholder="Выберите событие"
                  />
                </label>
              ) : (
                <label className={s.rangeWide}>
                  <span className={s.label}>Субъект:</span>
                  <CustomSelect
                    items={activeTypeItems}
                    onChange={referenceIdsHandle}
                    value={referenceIds}
                    isLoading={statsQuery.isLoading}
                    isWide={true}
                    withSearch={true}
                    placeholder="Выберите субъект"
                  />
                </label>
              )}
              <label className={s.rangeWide}>
                <span className={s.label}>Источник:</span>
                <CustomSelect
                  items={listSourse}
                  onChange={changeSourse}
                  value={sourse}
                  isLoading={sourcesListIsLoading}
                  isWide={true}
                  placeholder="Выберите источник"
                  withSearch={true}
                />
              </label>
            </>
          )}

          {/*<label className={s.check}>*/}
          {/*  <Checkbox*/}
          {/*    className={s.checkBox}*/}
          {/*    checked={onlyFederal}*/}
          {/*    onChange={() => setOnlyFederal((prevState) => !prevState)}*/}
          {/*  />*/}
          {/*  <span className={s.checkText}>Федеральная повестка</span>*/}
          {/*</label>*/}
        </div>
      </Panel>

      <ApiInfo
        isLoading={dataIsLoading || filterInProgress}
        isError={false}
        error={undefined}
      />
      {!dataIsLoading && !filterInProgress && filteredData?.length < 1 && <NoData />}
      {!dataIsLoading && !filterInProgress && filteredData?.length > 0 && (
        <div className={s.list}>
          {filteredData?.map((item: any, index: number) => (
            <Topic
              key={item?.id + index}
              number={index + 1}
              data={item}
              referenceId={reference_id}
              profileId={profileID}
              networkId={networkID}
              type={type}
            />
          ))}
        </div>
      )}
    </>
  );
};
