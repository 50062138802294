import { useEffect, useState } from "react";

import {Button, PageTitle, Panel, ApiInfo, NoData, Trend, Checkbox} from '../../ui'
import s from "./trending.module.css";
import { ApiServices } from "../../services/api-services";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query/react";
import { SerializedError } from "@reduxjs/toolkit";
import { TrendingSbj } from "./trending_sbj";

type SateType = "subject" | "event" | "smi";
type ErrorType = FetchBaseQueryError | SerializedError;

export const Trending = () => {
  const [type, setType] = useState<SateType>("subject");
  const [period, setPeriod] = useState('short')
  // const [isShort, setIsShort] = useState<boolean>(false);
  const [isSubject, setIsSubject] = useState<boolean>(false);
  const [items, setItems] = useState([] as any[]);
  const [filteredData, setFilteredData] = useState([] as any[]);
  const [onlyFederal, setOnlyFederal] = useState(false);
  const [isFederal, setIsFederal] = useState<any>([]);

  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isFetching, setIsFetching] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [error, setError] = useState<ErrorType | undefined>(undefined);

  const { REACT_APP_EVENTS_ID, REACT_APP_SUBJECTS_ID } = process.env;
  const reference_id =
    type === "event" ? REACT_APP_EVENTS_ID : REACT_APP_SUBJECTS_ID;

  useEffect(() => {
    setIsFetching(true)
    getAllData();
  }, [period]);

  const updateData = (value:any) => {
    isFederal.push(value)
    setIsFederal(isFederal)
 }

  useEffect(() => {
    items.map((it: any, id:any) =>{
      it["federal"] = isFederal[id];
    })
    setFilteredData(
      onlyFederal ? items?.filter((it: any) => it?.federal > 0) : items
    );
  }, [onlyFederal, items]);

  const getAllData = async () => {
    let ratingResponse: { data: any; error: any };

    if (period === 'short') {
      ratingResponse = await ApiServices.getTopicRatingShort();
    } else {
      ratingResponse = await ApiServices.getTopicRating();
    }

    if (ratingResponse.data) {
      const tempTopicRatingList = [];

      for (const topicRating of ratingResponse.data) {
        const topicResponse = await ApiServices.getTopic({
          id:
            topicRating.id.length > 10 ? topicRating.id : topicRating.group_id,
          type: "all",
          referenceFilter: [parseInt(reference_id!)],
        });
        tempTopicRatingList.push({
          ...topicRating,
          details: topicResponse.data,
        });
      }

      setItems(
        tempTopicRatingList.sort((a: any, b: any) =>
          a.details.postcount < b.details.postcount ? 1 : -1
        )
      );
      setIsFetching(false);
      setIsSuccess(true);
      setIsError(false);
      setError(undefined);
    }
    if (ratingResponse.error) {
      setIsFetching(false);
      setIsSuccess(false);
      setIsError(true);
      setError(ratingResponse.error);
    }
  };

  const lastHourSet = () => {
    setIsSubject((prevState) => !prevState);
    setPeriod('short')
    // setIsShort(false);
  };
  const lastDaySet = () => {
    setIsSubject((prevState) => !prevState);
    setPeriod('day')
    // setIsShort(false);
  };
  const subjectSet = () => {
    setIsSubject((prevState) => !prevState);
    setPeriod('short')
    // setIsShort(true);
  };

  return (
    <>
      <PageTitle>Быстрорастущие темы</PageTitle>
      <Panel padding className={s.panelFlex}>
        {!isSubject ? (
          <>
            <Button
              className={s.tab}
              active={period === 'day'}
              onClick={() => setPeriod('day')}
            >
              За текущие сутки
            </Button>
            <Button
              className={s.tab}
              active={period === 'short'}
              onClick={() => setPeriod('short')}
            >
              За последние 3 часа
            </Button>
            {/*<Button*/}
            {/*  className={s.fedtab}*/}
            {/*  active={onlyFederal}*/}
            {/*  onClick={() =>setOnlyFederal((prevState) => !prevState)}*/}
            {/*>*/}
            {/*  Федеральная повестка*/}
            {/*</Button>*/}
          </>
        ) : (
          <>
            <Button
              className={s.tab}
              active={period === 'day'}
              onClick={() => lastDaySet()}
            >
              За текущие сутки
            </Button>
            <Button
              className={s.tab}
              active={period === 'short' && !isSubject}
              onClick={() => lastHourSet()}
            >
              За последние 3 часа
            </Button>
          </>
        )}
        <Button
          className={s.tab}
          active={isSubject}
          onClick={() => subjectSet()}
        >
          Темы по субъектам
        </Button>

        {!isSubject ? (
          <Button
            className={s.fedtab}
            active={onlyFederal}
            onClick={() =>setOnlyFederal((prevState) => !prevState)}
          >
            Федеральная повестка
          </Button>
          ) : null }

        {/*{!isSubject ? (*/}
        {/*  <div className={s.fedtab}>*/}
        {/*    <label className={s.check}>*/}
        {/*      <Checkbox*/}
        {/*        className={s.checkBox}*/}
        {/*        checked={onlyFederal}*/}
        {/*        onChange={() => setOnlyFederal((prevState) => !prevState)}*/}
        {/*      />*/}
        {/*      <span className={s.checkText}>Федеральная повестка</span>*/}
        {/*    </label>*/}
        {/*  </div>*/}
        {/*  ) : null }*/}
      </Panel>
      {!isSubject ? (
        <>
          <ApiInfo isLoading={isFetching} isError={isError} error={error} />
          {!isFetching && isSuccess && filteredData.length < 1 && <NoData />}
          {!isFetching && isSuccess && filteredData.length > 0 && (
            <div className={s.list}>
              {filteredData.map((item: any, index: number) => (
                <Trend
                  key={item.id}
                  number={index + 1}
                  data={item}
                  referenceId={reference_id}
                  onlyFederal={onlyFederal}
                  updateData={updateData}
                />
              ))}
            </div>
          )}
        </>
      ) : (
        <TrendingSbj />
      )}
    </>
  );
};
