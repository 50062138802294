import clsx from "clsx";
import {useEffect, useState} from 'react'
import { Modal } from "react-bootstrap";
import { Button, Checkbox, Select } from "..";
import "./change-tonal-request.css";
import "bootstrap/dist/css/bootstrap.min.css";
import moment from "moment";
import { ApiServices } from "../../services/api-services";
import { DeleteOutlined } from "@ant-design/icons";

type Props = {
  className?: string;
  trust: number;
  uri: string;
  text: string;
};

export const ChangeTonalRequest = (props: Props) => {
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState('');
  const [tonal, setTonal] = useState(props.trust)
  const [tonalChanged, setTonalChanged] = useState(false)

  useEffect(() => {
    if (tonal === props.trust && tonalChanged) {
      setTonalChanged(false)
      return
    }
    setTonalChanged(true)
  }, [tonal])

  const handleOk = (): void => {
    sendData();
  };

  const sendData = async () => {
    const params = {
      url: props.uri,
      text: props.text,
      trust: tonal.toString(),
      trust_comment: message
    }

    await ApiServices.sendChangeTonalRequest(params);
    handleClose()
  };

  const handleClose = () => {
    setShow(false);
  }

  const handleShow = () => setShow(true);

  return (
    <div>
      <Button className={props.className} onClick={handleShow}>
        Отправить запрос на изменение тональности
      </Button>
      <Modal show={show} onHide={handleClose} style={{ zIndex: 10000 }}>
        <Modal.Header closeButton>
          <Modal.Title>Запрос на изменение тональности</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="change-tonal-request-modal">
            <div className="change-tonal-request-modal__wrap">
              <div className="change-tonal-request-modal__list">
                <div
                  className={`change-tonal-request-modal__item positive${tonal === 1 ? ' active' : ''}`}
                  onClick={() => setTonal(1)}
                >
                  Положительная
                </div>
                <div
                  className={`change-tonal-request-modal__item${tonal === 0 ? ' active' : ''}`}
                  onClick={() => setTonal(0)}
                >
                  Нейтральная
                </div>
                <div
                  className={`change-tonal-request-modal__item negative${tonal === -1 ? ' active' : ''}`}
                  onClick={() => setTonal(-1)}
                >
                  Негативная
                </div>
              </div>
              <textarea onChange={(evt) => setMessage(evt.currentTarget.value)} />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose}>Отменить</Button>

          <Button onClick={handleOk}>
            Отправить
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
