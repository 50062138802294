import clsx from "clsx";
import { useEffect } from "react";
import { PostRaiting } from "..";
import { TopicPopup, useTopicsModalQuery } from "../../features";
import s from "./trending.module.css";

type Props = {
  data: any;
  number: number;
  className?: string;
  referenceId?: string;
  tonal?: string;
  onlyFederal?: boolean;
  updateData?: any
};

export const Trend = ({ number, className, data, referenceId, tonal, onlyFederal, updateData}: Props) => {
  console.log('data', data)
  const publicationsQuery = useTopicsModalQuery({
    id: data.id.length > 10 ? data.id : data.group_id,
    type: 'all',
    referenceFilter: [parseInt(referenceId!)]
  });

  useEffect(() => {
    const listOfSources = data.details.items.filter((it: any) => it.smi_type === "federal")
    console.log("listOfSources", listOfSources?.length);
    updateData(data['federal']=listOfSources?.length)

    const postsFederal = {
        id:data?.id ,
        federal: postFederal,

      }
    //updateFederal(postsFederal)
  }, [data]);

  const getGraphData = (template: any, items: any): any => {
    const result: any = []
    template.forEach((row: any) => {
      let itemsCount = 0
      items.forEach((item: any) => {
        if (item.created_date.split(' ')[1].split(':')[0] === row[0].split(' ')[1].split(':')[0]) {
          itemsCount++
        }
      })
      result.push([row[0], itemsCount])
    })
    return result
  }

  const postRatingData = {
    id: data.id,
    group_id: data.group_id,
    topic: data.title,
    subject: data.reference_item,
    level: 0,
    subjectPublications: data.details.items.length,
    subjectCoverage: data.attendance,
    totalPublications: data.owners_count,
    totalCoverage: data.total_attendance,
    dynamicData: getGraphData(data.graph, data.details.items),
    mediaPublications: data.smi_total_posts,
    socialMediaPublications: data.social_total_posts,
    publications: data.details
  };

  const postNetural = data.details.items.filter((it: any) => it.trust.trust === 0).length;
  const postNegative = data.details.items.filter((it: any) => it.trust.trust === -1).length;
  const postPositive = data.details.items.filter((it: any) => it.trust.trust === 1).length;
  const postSumm = postNetural + postNegative + postPositive;
  tonal = postNegative*100/postSumm >= 25 ? 'trending_negative' : postPositive*100/postSumm >= 25 ? 'trending_positive' : ''
  const postFederal = data.details.items.filter((it: any) => it.smi_type === "federal") || [];

  const content = (
    <div className={className}>
      {(onlyFederal && postFederal.length > 0)  &&
      <PostRaiting
        number={number}
        coat={postFederal.length > 0}
        className={clsx(s.topic, s[tonal])}
        data={postRatingData}
      />
      }
      {(!onlyFederal)  &&
      <PostRaiting
        number={number}
        coat={postFederal.length > 0}
        className={clsx(s.topic, s[tonal])}
        data={postRatingData}
      />
      }
    </div>
  );

  return <TopicPopup isTrending={true} content={content} data={postRatingData} referenceId={referenceId} profileId='all'/>;
};
