import clsx from "clsx";
import moment from 'moment'
import { networks as filterNetworks, TrustValue } from "../../../data";
import { Checkbox, DateTuple, TextButton } from "../../../ui";
import { useEffect, useState } from "react";
import { Button, DateRangePicker, Panel, Select } from "../../../ui";
import s from "./publications-filters.module.css";
import { FilterOutlined} from '@ant-design/icons'
import CustomSelect from "../../../ui/custom-select/custom-select";
import { DatePicker } from 'antd'
import locale from 'antd/es/date-picker/locale/ru_RU'
import {ApiServices} from '../../../services/api-services'
const { RangePicker } = DatePicker

export type FilterData = {
  network_id?: number[];
  from?: string;
  to?: string;
  trustoption?: TrustValue;
  order?: string;
  type?: string;
  profile_id?: number;
};

type Props = {
  expanded: boolean;
  onExpandToggle: (expanded: boolean) => void;
  onFilterChange: (data: FilterData) => void;
  className?: string;
  selectedSource?: string;
  sourcesList?: any;
  sourcesListIsLoading?: boolean
};

export const PublicationsFilters = (props: Props) => {
  const { className, onFilterChange, expanded, onExpandToggle, sourcesList, sourcesListIsLoading, selectedSource } = props;
  const [smi, setSmi] = useState(true);
  const [networks, setNetworks] = useState<number[]>([1,2,3,5,7,8]);
  const [dates, setDates] = useState([
    moment(new Date()).format('DD-MM-YYYY') + ' 00:00:00',
    moment(new Date()).format('DD-MM-YYYY') + ' 23:59:59'
  ])
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(new Date());
  const [trust, setTrust] = useState<TrustValue>("");
  const [sorting, setSorting] = useState('')
  const [sortingType, setSortingType] = useState('')
  const [sortingOrder, setSortingOrder] = useState('')
  const [sourse, setSource] = useState<string | number>(selectedSource || '')
  const [retro, setRetro] = useState(moment(new Date()).format('DD-MM-YYYY') + ' 00:00:00')

  useEffect(() => {
    getThreadData()
  }, [])

  const getThreadData = async () => {
    const threadResponse = await ApiServices.getThread()

    if (threadResponse.data) {
      const currentThread = threadResponse.data.find((it: any) => it.id === process.env.REACT_APP_THREAD_ID)
      setRetro(currentThread.retro)
    }
  }

  useEffect(() => {
    setSource(selectedSource || '')
    const apiIds = [...networks];
    if (smi) apiIds.push(filterNetworks.smi.id);
    const sourceItem = sourcesList ? sourcesList.find((it: any) => it.url === selectedSource) : undefined
    onFilterChange({
      from: dates[0],
      to: dates[1],
      trustoption: trust === "" ? undefined : trust,
      order: sortingOrder === "" ? undefined : sortingOrder,
      type: sortingType === "" ? undefined : sortingType,
      network_id: apiIds.length > 0 ? apiIds : undefined,
      profile_id: sourceItem ? sourceItem.profile_id : undefined
    });
  }, [selectedSource])

  function applyChanges() {
    const apiIds = [...networks];
    if (smi) apiIds.push(filterNetworks.smi.id);
    const sourceItem = sourcesList ? sourcesList.find((it: any) => it.url === sourse) : undefined
    onFilterChange({
      from: dates[0],
      to: dates[1],
      trustoption: trust === "" ? undefined : trust,
      order: sortingOrder === "" ? undefined : sortingOrder,
      type: sortingType === "" ? undefined : sortingType,
      network_id: apiIds.length > 0 ? apiIds : undefined,
      profile_id: sourceItem ? sourceItem.profile_id : undefined
    });
  }

  function reset() {
    setDates([
      moment(new Date()).format('DD-MM-YYYY') + ' 00:00:00',
      moment(new Date()).format('DD-MM-YYYY') + ' 23:59:59'
    ]);
    setTrust("");
    setSorting("");
    setSmi(true);
    setNetworks([1,2,3,5,7,8]);
    onFilterChange({});
  }

  function toggleSocials(checked: boolean) {
    const ids = filterNetworks.socials.map(({ id }) => id);
    setNetworks(checked ? ids : []);
  }

  function toggleSocial(id: number, checked: boolean) {
    const filtered = networks.filter((stateId) => stateId !== id);
    setNetworks(checked ? [...filtered, id] : filtered);
  }

  function selectAll() {
    toggleSocials(true);
    setSmi(true);
  }

  function sortingChange  (checkedValues: string): void  {
    setSorting(checkedValues)
    {checkedValues.substring(0,1) =="a" ?
      setSortingOrder('asc'):
      setSortingOrder('desc')
    }
    setSortingType(checkedValues.substring(1))
  }

  const changeSourse = (value:any) => {
    setSource(value)
  }

  const disabledDate = (current: any): boolean => {
    if (moment(current).isAfter(moment(new Date(), 'DD-MM-YYYY')) || moment(current).isBefore(moment(retro, 'YYYY-MM-DD'))) {
      return true
    }

    return false
  }

  const onPeriodChange = (value: any, dateString: string[]): void => {
    setDates([dateString[0] + ' 00:00:00', dateString[1] + ' 23:59:59'])
    // setIsApplyButtonDisabled(false)
  }

  return (
    <>
    <header className={s.header} onClick={() => onExpandToggle(!expanded)}>
      <FilterOutlined />
    </header>
    {expanded && (
    <Panel className={clsx(s.filters, className)} padding>
        <form
          className={s.content}
          onSubmit={(event) => {
            event.preventDefault();
            applyChanges();
          }}
        >
          <span className={s.subTitle}>Период</span>
          {/*<DateRangePicker*/}
          {/*  startDate={startDate}*/}
          {/*  endDate={endDate}*/}
          {/*  onChange={([startDate, endDate]: DateTuple) => {*/}
          {/*    setStartDate(startDate);*/}
          {/*    setEndDate(endDate);*/}
          {/*  }}*/}
          {/*/>*/}
          <RangePicker
            value={[moment(dates[0], 'DD-MM-YYYY HH-mm-SS'), moment(dates[1], 'DD-MM-YYYY HH-mm-SS')]}
            format={'DD-MM-YYYY'}
            disabledDate={disabledDate}
            onChange={onPeriodChange}
            style={{ width: '100%' }}
            allowClear={false}
            locale={locale}
          />
          <div className={s.sourceLine}>
            <span className={s.subTitle}>Источники</span>
            <TextButton onClick={() => selectAll()}>Выбрать все</TextButton>
          </div>


          {/*<label className={s.check}>
            <Checkbox
              className={s.checkBox}
              checked={filterNetworks.socials.every(({ id }) =>
                networks.includes(id)
              )}
              onChange={({ target }) => toggleSocials(target.checked)}
            />
            <span className={s.checkText}>Социальные сети</span>
          </label>*/}
          <div className={s.checkGroup}>
            {filterNetworks.socials.map(({ id, name }) => (
              <label key={id} className={s.check}>
                <Checkbox
                  className={s.checkBox}
                  checked={networks.includes(id)}
                  onChange={({ target }) => toggleSocial(id, target.checked)}
                />
                <span className={s.checkText}>{name}</span>
              </label>
            ))}
          <label className={s.check}>
            <Checkbox
              className={s.checkBox}
              checked={smi}
              onChange={() => setSmi((prevState) => !prevState)}
            />
            <span className={s.checkText}>СМИ</span>
          </label>
          </div>


          <span className={s.subTitle}>Тональность публикаций</span>
          <Select
            value={trust}
            onChange={(event) => setTrust(event.target.value as TrustValue)}
          >
            <option value="">Любая</option>
            <option value="1">Позитивная</option>
            <option value="-1">Негативная</option>
            <option value="0">Нейтральная</option>
          </Select>

        <span className={s.subTitle}>Сортировка публикаций</span>
        <Select
          value={sorting}
          onChange={(event) => sortingChange(event.target.value)}
        >
          <option value={'ddate'}>Сначала новые</option>
          <option value={'adate'}>Сначала старые</option>
          <option value={'dviewed'}>Больше просмотров</option>
          <option value={'aviewed'}>Меньше просмотров</option>
          <option value={'dcommentaries'}>Больше комментариев</option>
          <option value={'acommentaries'}>Меньше комментариев</option>
          <option value={'dreposts'}>Больше репостов</option>
          <option value={'areposts'}>Меньше репостов</option>
          <option value={'dlikes'}>Больше лайков</option>
          <option value={'alikes'}>Меньше лайков</option>
        </Select>

          {sourcesList
            ? (
              <>
                <span className={s.subTitle}>Источники</span>
                <CustomSelect
                  items={[{ id: '', keyword: 'Все'}, ...sourcesList.map((it: any) => ({ id: it.url, keyword: it.title}))]}
                  onChange={(value) => changeSourse(value)}
                  value={sourse}
                  isLoading={sourcesListIsLoading}
                  isWide={true}
                  placeholder="Выберите источник"
                  withSearch={true}
                />
              </>
            )
            : null
          }

          <div className={s.buttons}>
            <Button active={true}>Применить</Button>
            <TextButton className={s.reset} onClick={reset}>
              Сбросить
            </TextButton>
          </div>
        </form>
    </Panel>      )}
    </>
  );
};
