export const getFilteredData = (list: any[], searchString: string, onlyFederal: boolean, source: string): any[] => {
  let result = [...list].sort((a: any, b: any) => a.attendance < b.attendance ? 1 : -1);
  if (searchString) {
    result = [...result].filter((it: { title: string }) => it.title.toLowerCase().includes(searchString.toLowerCase()));
  }
  if (onlyFederal) {
    result = [...result].filter((it: { isFederal: string }) => it.isFederal);
  }
  if (source) {
    result = [...result].filter((it: any) => it.details.items.map((detailItem: any) => detailItem.from_id).includes(source));
  }
  return result
}
