import { SyntheticEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCheckAuthQuery, useLogInMutation } from "../../features";
import { ApiServices } from "../../services/api-services";
import { ApiInfo, Button, Field, Panel } from "../../ui";
import s from "./login.module.css";

// sergey@glassen-it.com
// 112233

const testData = {
  login:
    process.env.NODE_ENV === "development"
      ? "test_system_glassen@gmail.com"
      : "",
  password: process.env.NODE_ENV === "development" ? "Qwerty1357901" : "",
};

export function Login() {
  let navigate = useNavigate();
  const [login, setLogin] = useState(testData.login);
  const [currentUrl, setCurrentUrl] = useState(window.location);
  const [password, setPassword] = useState(testData.password);
  const [fetchAuth, response] = useLogInMutation();
  const { isSuccess, isLoading, isError, error } = response;
  const authCheck = useCheckAuthQuery({})
  const { } = authCheck;
  const getAuthData = async () => {
    const checkAuth = await ApiServices.getAuthCheck()
      if (checkAuth?.data) console.log("/");
  }

  function handleSubmit(event: SyntheticEvent<HTMLFormElement>) {
    event.preventDefault();
    fetchAuth({ login, password });
  }

  useEffect(() => {
    getAuthData()
    if (isSuccess) navigate("/");
    currentUrl.toString().slice(0,5)
    setCurrentUrl(currentUrl)
  }, [isSuccess]);

  return (
    <div className={s.container}>
      <Panel className={s.panel} padding>
        <form className={s.form} onSubmit={handleSubmit}>
          {!isLoading && (
            <>
              <Field
                required
                placeholder="Логин"
                value={login}
                onChange={(e) => setLogin(e.target.value)}
              />
              <Field
                type="password"
                placeholder="Пароль"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <div className={s.flex}>
                <Button active>Войти</Button>
                <a
                  className={s.btn}
                  href={`https://isiao.glassen-it.com/component/slogin/provider/isiao/auth?front_redirect=${currentUrl.toString().slice(0,-5)}`}
                  // href={`https://isiao.glassen-it.com/component/slogin/provider/isiao/auth?front_redirect=http://localhost:8080/`}
                >
                  Войти через ИС ИАО
                </a>
              </div>
            </>
          )}
          <ApiInfo
            errorClassName={s.error}
            isLoading={isLoading}
            isError={isError}
            error={error}
          />
        </form>
      </Panel>
    </div>
  );
}
