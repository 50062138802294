import clsx from "clsx";
import { Panel } from "../panel";
import { Tooltip } from "antd";
import { Statistics } from "../statistics";
import news from "./img/icon-source.svg";
import iconTelegram from "./img/icon-telegram.svg";
import iconFacebook from "./img/icon-facebook.svg";
import iconInstagram from "./img/icon-instagram.svg";
import iconVk from "./img/icon-vk.svg";
import iconTwitter from "./img/icon-twitter.svg";
import iconYouTube from "./img/icon-youtube.svg";
import fallback from "./img/fallback.svg";
import s from "./post.module.css";
import { useEffect, useState } from "react";
import { Button } from "../button";
//import  Image  from '../../ui/image/index'
//import PhotoSwipeLightbox from "photoswipe/lightbox";
import "photoswipe/style.css";
import { Gallery, Item } from "react-photoswipe-gallery";
import {
  CaretRightOutlined,
  CloseSquareOutlined,
  ReadOutlined,
  FrownTwoTone,
  SmileTwoTone,
  PlusOutlined, DeleteOutlined
} from '@ant-design/icons'
import { CreateEvent } from "../create-event";
import {ApiServices} from '../../services/api-services'
import {ChangeTonal} from '../change-tonal'
import {ChangeTonalRequest} from '../change-tonal-request'
import {PostActions} from '../post-action'
import {Modal} from 'react-bootstrap'
import {Select} from '../select'
import {Checkbox} from '../checkbox'

export type Author = {
  name: string;
  avatar: string;
  url: string;
};

export type Statistic = {
  people: number;
  views: number;
  likes: number;
  comments: number;
  reposts: number;
};

export type Media = {
  images: any;
  video: any;
};

export type PostProps = {
  id: string;
  title: string;
  author: Author;
  text: string;
  url?: string;
  uri?: string;
  coat: boolean;
  date: Date;
  statistics: Statistic;
  className?: string;
  type?: string;
  media: Media;
  networkName?: string;
  networkId?: string | number;
  referenceId?: string | number;
  ownerId?: string | number;
  onCreateEvent?: (data: any) => void;
  postIsManual?: boolean
  trust?: any
};

const dateOptions: Intl.DateTimeFormatOptions = {
  day: "numeric",
  month: "numeric",
  year: "2-digit",
};

const timeOptions: Intl.DateTimeFormatOptions = {
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
};

export function Post(props: PostProps) {
  const {
    id,
    className,
    title,
    author,
    text,
    url,
    uri,
    type,
    statistics,
    coat,
    date,
    media,
    networkName,
    onCreateEvent,
    referenceId,
    networkId,
    ownerId,
    postIsManual,
    trust
  } = props;
  const [newType, setNewType] = useState(type)
  const [currentTonal, setCurrentTonal] = useState(trust)

  const [isRemoved, setIsRemoved] = useState(false)
  const [showFullText, setShowFullText] = useState(false);
  const [showAllImages, setShowAllImages] = useState(false);
  const [showCreateEventModal, setShowCreateEventModal] = useState(false);
  const shortImages = media.images.slice(0, 3);

  const regex = /(<([^>]+)>)/gi;
  const safeText = text.replace(regex, "");
  const slicedText =
    safeText.length > 300 ? safeText.slice(0, 460) + "…" : safeText;

  const smallItemStyles: React.CSSProperties = {
    cursor: "pointer",
    objectFit: "cover",
    width: "100px",
    height: "70px",
  };

  let sourceIcon: string;
  switch (networkName) {
    case "tg":
      sourceIcon = iconTelegram;
      break;
    case "fb":
      sourceIcon = iconFacebook;
      break;
    case "ig":
      sourceIcon = iconInstagram;
      break;
    case "tw":
      sourceIcon = iconTwitter;
      break;
    case "vk":
      sourceIcon = iconVk;
      break;
    case "yt":
      sourceIcon = iconYouTube;
      break;
    default:
      sourceIcon = news;
  }

  const removePost = () => {
    if (referenceId) {
      const params = {
        reference_item_id: Number(referenceId),
        post_id: id,
        owner_id: Number(ownerId),
        network_id: Number(networkId)
      }
      ApiServices.addRefStopPost(params).then(resp => {
        setIsRemoved(true)
      })
    }
  }

  const backRemovedPost = () => {
    if (referenceId) {
      const params = {
        reference_item_id: Number(referenceId),
        post_id: id,
        owner_id: Number(ownerId),
        network_id: Number(networkId)
      }
      ApiServices.removeRefStopPost(params).then(resp => {
        setIsRemoved(false)
      })
    }
  }

  const tonalChangeHandler = (value: number) => {
    if (value === 1) {
      setNewType("positive")
    }
    if (value === 0) {
      setNewType("default")
    }
    if (value === -1) {
      setNewType("negative")
    }
    setCurrentTonal(value)
  }
  // const createEventHandle = () => {
  //   if (onCreateEvent && url) {
  //     onCreateEvent({
  //       url,
  //       text,
  //     });
  //   }
  // };

  const changeTrust = async (value: number): Promise<void> => {
    if (currentTonal === value) {
      return;
    }
    const params = {
      post_id: id,
      owner_id: ownerId,
      network_id: networkId,
      trust: value,
      is_manual: postIsManual
    }

    await ApiServices.setPostTrust(params);
    setCurrentTonal(value)

    tonalChangeHandler(value)
    // if (value == 0) {
    //   changeTonal(post)
    // } else {
    //   changeTonal(trust)
    //   dispatch(changePostTrust(id, ownerId, networkId, postIsManual, value));
    // }
  };

  return (
    <div className={s.postFlex}>
    <Panel className={clsx(className, s.panel)} coat={coat}>
      <div
        className={clsx(s.post, {
          [s.default]: newType === "default",
          [s.negative]: newType === "negative",
          [s.positive]: newType === "positive",
        })}
      >
        {isRemoved && (
          <div className={s.removed}>
            <span>Пост будет удален из выдачи</span>
            <div
              className={s.removedBtn}
              onClick={backRemovedPost}
            >
              Вернуть
            </div>
          </div>
        )}
        <div className={s.title}>
          <a href={author.url} className={s.origin}>
            <div className={s.icons}>
              <img
                className={s.avatar}
                src={author.avatar}
                alt=""
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = fallback;
                }}
              />
              <img className={s.avatarIcon} src={sourceIcon} alt="" />
            </div>
            <div className={s.info}>
              <p className={s.site}>{author.name}</p>
              <p className={s.date}>
                {date.toLocaleDateString("ru", dateOptions)}
                &nbsp; | &nbsp;
                {date.toLocaleTimeString("ru", timeOptions)}
              </p>
            </div>
          </a>
          <Statistics
            className={s.statistics}
            data={statistics}
            networkName={networkName}
          />
        </div>
        <div className={s.textcontain}>
          <div className={s.uri}>
            <p className={s.headline}>{title ?? "–"}</p>
            <p className={s.text}>
              {safeText.length > 500 && !showFullText ? (
                <p className={s.storttext}>{slicedText} </p>
              ) : (
                safeText
              )}{" "}
            </p>
          </div>
          <p className={s.show}>
            {safeText.length > 500 && !showFullText ? (
              <span
                className={s.showMore}
                onClick={() => setShowFullText(true)}
              >
                Читать далее
              </span>
            ) : null}
          </p>
        </div>
        <div className={s.media}>
          {media.images.length > 0 && (
            <Gallery>
              <div
                style={{
                  display: "flex",
                  gridTemplateColumns: "auto-track-list",
                  gridTemplateRows: "70px",
                  gridGap: 10,
                  marginTop: "10px",
                }}
              >
                {media.images.length > 3 && !showAllImages
                  ? shortImages?.map((item: { url: string | undefined }) => (
                      <Item
                        original={item.url}
                        thumbnail={item.url}
                        width="1600"
                        height="1000"
                        alt={item.url}
                      >
                        {({ ref, open }) => (
                          <img
                            style={smallItemStyles}
                            src={item.url}
                            ref={
                              ref as React.MutableRefObject<HTMLImageElement>
                            }
                            onClick={open}
                          />
                        )}
                      </Item>
                    ))
                  : media?.images?.map((item: { url: string | undefined }) => (
                      <Item
                        original={item.url}
                        thumbnail={item.url}
                        width="1600"
                        height="1000"
                        alt={item.url}
                      >
                        {({ ref, open }) => (
                          <img
                            style={smallItemStyles}
                            src={item.url}
                            ref={
                              ref as React.MutableRefObject<HTMLImageElement>
                            }
                            onClick={open}
                          />
                        )}
                      </Item>
                    ))}
              </div>
            </Gallery>
          )}

          {media.images.length > 3 && !showAllImages && (
            <div
              className={s.more}
              onClick={() => setShowAllImages((prevState) => !prevState)}
            >
              Ещё {media.images.length - 3} фото
            </div>
          )}

          {media.video.length > 0 &&
            media.video.map((item: { url: string | undefined; image: any }) => (
              <a className={s.video} href={item?.url} target="_blank">
                <img width={100} src={item?.image} />
                <i className={s.videoicon}>
                  <CaretRightOutlined />
                </i>
              </a>
            ))}
        </div>
        <div className={s.footer}>
          <ChangeTonalRequest
            className={s.footerBtn}
            uri={uri || ''}
            text={text}
            trust={trust}
          />
          {/*<Button type="button" className={s.footerBtn} >Запрос на изменение тональности</Button>*/}
        </div>
      </div>
    </Panel>
        <div className="post-menu__wrap in-line">
          <Tooltip placement="bottomLeft" title="Прочитать публикацию">
            <div className="post-menu__item">
              <a
                href={uri}
                className="post-menu__item"
                target="_blank"
                rel="noreferrer"
              >
                <ReadOutlined />
              </a>
            </div>
          </Tooltip>
          {/*{(userGroup == '2001' || userGroup == '2002' || userGroup == '2003' || userGroup == '2004' || userGroup == '2005' || userGroup == '2016' || userGroup == '1076' || userGroup == '0') &&*/}
          <Tooltip placement="bottomLeft" title="Изменить тональность">
          <div className="post-menu__trust">
            
              <ChangeTonal ownerId={ownerId} networkId={networkId} postId={id} trust={currentTonal} onTonalChange={tonalChangeHandler}>
                <>
                  {currentTonal == 1 && (
                    <SmileTwoTone
                      key="1"
                      twoToneColor="#52c41a"
                      // onClick={() => setShowTonal((prevState) => !prevState)}
                    />
                  )}
                  {currentTonal == -1 && (
                    <FrownTwoTone
                      key="-1"
                      twoToneColor="red"
                      // onClick={() => setShowTonal((prevState) => !prevState)}
                    />
                  )}
                  {currentTonal != 1 && currentTonal != -1 && (
                    <span
                      key="0"
                      role="img"
                      // onClick={() => setShowTonal((prevState) => !prevState)}
                    >
                  <svg width="17" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g
                      clipPath="url(#v)"
                      stroke="currentColor"
                      strokeWidth="1.143"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M4.004 10.857h8M8.005 15.429a7.429 7.429 0 1 0 0-14.858 7.429 7.429 0 0 0 0 14.858z" />
                      <path d="M5.49 6.229a.286.286 0 0 1 0-.572M5.49 6.229a.286.286 0 1 0 0-.572M10.519 6.229a.286.286 0 1 1 0-.572M10.519 6.229a.286.286 0 1 0 0-.572" />
                    </g>
                    <defs>
                      <clipPath id="v">
                        <path fill="#fff" transform="translate(.004)" d="M0 0h16v16H0z" />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
                  )}
                </>
              </ChangeTonal>

              {/*{*/}
              {/*  <div className="post-menu__item post-menu__item-tonal">*/}
              {/*    <FrownTwoTone*/}
              {/*      key="-1"*/}
              {/*      twoToneColor="red"*/}
              {/*      onClick={() => changeTrust(-1)}*/}
              {/*    />*/}
              {/*    <span*/}
              {/*      key="0"*/}
              {/*      role="img"*/}
              {/*      onClick={() => changeTrust(0)}*/}
              {/*    >*/}
              {/*    <svg width="17" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
              {/*      <g*/}
              {/*        clipPath="url(#v)"*/}
              {/*        stroke="currentColor"*/}
              {/*        strokeWidth="1.143"*/}
              {/*        strokeLinecap="round"*/}
              {/*        strokeLinejoin="round"*/}
              {/*      >*/}
              {/*        <path d="M4.004 10.857h8M8.005 15.429a7.429 7.429 0 1 0 0-14.858 7.429 7.429 0 0 0 0 14.858z" />*/}
              {/*        <path d="M5.49 6.229a.286.286 0 0 1 0-.572M5.49 6.229a.286.286 0 1 0 0-.572M10.519 6.229a.286.286 0 1 1 0-.572M10.519 6.229a.286.286 0 1 0 0-.572" />*/}
              {/*      </g>*/}
              {/*      <defs>*/}
              {/*        <clipPath id="v">*/}
              {/*          <path fill="#fff" transform="translate(.004)" d="M0 0h16v16H0z" />*/}
              {/*        </clipPath>*/}
              {/*      </defs>*/}
              {/*    </svg>*/}
              {/*  </span>*/}
              {/*    <SmileTwoTone*/}
              {/*      key="1"*/}
              {/*      twoToneColor="#52c41a"*/}
              {/*      onClick={() => changeTrust(1)}*/}
              {/*    />*/}
              {/*  </div>*/}
              {/*}*/}
            
          </div>
          </Tooltip>
          {/*}*/}
          <Tooltip placement="bottomLeft" title="Иcключить">
            <div
              className="post-menu__item"
              onClick={removePost}
            >
              <CloseSquareOutlined />
            </div>
          </Tooltip>
          {onCreateEvent ? (
            <Tooltip placement="bottomLeft" title="Создать события для дальнейшего мониторинга">
              <div
                className="post-menu__item"
                onClick={() => setShowCreateEventModal(true)}
              >
                <PlusOutlined />
              </div>
            </Tooltip>
          ) : null}
        </div>
      <CreateEvent
        data={uri}
        number={0}
        className="post-menu__item"
        showCreateEventModal={showCreateEventModal}
        hideCreateEventModal={() => setShowCreateEventModal(false)}
      />
    </div>
  );
}
