import clsx from "clsx";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { Button, Checkbox, Select } from "..";
import s from "./create-event.module.css";
import "bootstrap/dist/css/bootstrap.min.css";
import moment from "moment";
import { ApiServices } from "../../services/api-services";
import {DeleteOutlined} from '@ant-design/icons'

type Props = {
  data: any;
  number: number;
  className?: string;
  referenceId?: string;
  tonal?: string;
  tonalFilter?: any;
  showCreateEventModal: boolean
  hideCreateEventModal: () => void
};

export const CreateEvent = ({
  number,
  className,
  data,
  referenceId,
  tonal,
  tonalFilter,
  showCreateEventModal,
  hideCreateEventModal
}: Props) => {
  const [errorShow, setErrorShow] = useState(false);
  const [eventName, setEventName] = useState("");
  const [currentWord, setCurrentWord] = useState("");
  const [keyWords, setKeyWords] = useState<string[]>([]);
  const [retro, setRetro] = useState("1");
  const [source, setSource] = useState({
    smi: true,
    social: true,
  });
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [buttonState, setButtonState] = useState(true);

  const handleOk = (): void => {

    buttonState ? error() : sendData();
  };

  const error = () => setErrorShow(true);

  const onChangeRetro = (evt: any) => {
    setRetro(evt.target.value);
  };

  const onSourceSmiChange = (): void => {
    setSource((prevState) => ({
      ...prevState,
      smi: !prevState.smi,
    }));
  };

  const clearModalData = (): void => {
    setEventName("");
    setCurrentWord("");
    setKeyWords([]);
    setButtonDisabled(true);
    setButtonState(true);
  };

  const onSourceSocialChange = (): void => {
    setSource((prevState) => ({
      ...prevState,
      social: !prevState.social,
    }));
  };

  const changeName = (evt: string) => {
    setEventName(evt);
    if (!buttonDisabled && evt.length < 1) setButtonDisabled(true);
    else setButtonDisabled(false);
  };

  const addKeyWord = (): void => {
    setKeyWords((prevState) => [...prevState, currentWord]);
    setCurrentWord("");
    setButtonState(false);
  };

  const removeKeyWord = (index: number): void => {
    setKeyWords((prevState) => {
      const before = prevState.slice(0, index);
      const after = prevState.slice(index + 1);
      {
        prevState.slice(index + 1).length < 1
          ? setButtonState(true)
          : setButtonState(false);
      }
      return [...before, ...after];
    });
  };

  const sendData = async () => {
    clearModalData();
    const selectedSource = [];
    if (source.smi) {
      selectedSource.push("СМИ");
    }
    if (source.social) {
      selectedSource.push("Соцсети");
    }
    await ApiServices.sendEvent({
      url: data,
      title: eventName,
      keywords: keyWords,
      sources: selectedSource.join(", "),
      retro: moment(new Date()).subtract(retro, "days").format("YYYY-MM-DD"),
    });
    handleClose()
  };

  const handleErrorClose = () => setErrorShow(false);
  const handleClose = () => hideCreateEventModal();

  return (
    <div>
      <Modal show={showCreateEventModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Создание события</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="create-event-modal-form__theme">
            <div className="create-event-modal-form__label">Тема события</div>
            <input
              value={eventName}
              onChange={(evt) => changeName(evt.target.value)}
            />
          </div>

          {keyWords.length > 0 && (
            <div className="create-event-modal-form__words">
              <div className="create-event-modal-form__label">
                Ключевые слова
              </div>
              <ul className="create-event-modal-form__words-list">
                {keyWords.map((word, index) => {
                  return (
                    <li key={word}>
                      {word}
                      <DeleteOutlined
                        onClick={() => removeKeyWord(index)}
                        style={{ marginLeft: "5px" }}
                      />
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
          <div className="create-event-modal-form__add-word">
            <div className="create-event-modal-form__label">
              Введите ключевое слово
            </div>
            <div className={s.keyWords}>
              <input
                value={currentWord}
                onChange={(evt) => setCurrentWord(evt.target.value)}
              />
              <Button
                disabled={currentWord == "" ? true : false}
                style={{ marginLeft: "5px" }}
                onClick={addKeyWord}
              >
                Добавить
              </Button>
            </div>
          </div>

          <div className="create-event-modal-form__label">
            Выбор ретроспективы
          </div>
          <Select
            value={retro}
            onChange={onChangeRetro}
            style={{ width: "100%", padding: "5px" }}
          >
            <option value="1">Сегодня</option>
            <option value="7">7 дней</option>)
            <option value="14">14 дней</option>)
            <option value="30">30 дней</option>)
          </Select>

          <div className="create-event-modal-form__source">
            <div className="create-event-modal-form__label">
              Осуществлять поиск в
            </div>
            <div className="create-event-modal-form__row">
              <label className={s.check}>
                <Checkbox
                  className={s.checkBox}
                  checked={source.smi}
                  onChange={onSourceSmiChange}
                />
                <span className={s.checkText}>СМИ</span>
              </label>
              <label className={s.check}>
                <Checkbox
                  className={s.checkBox}
                  checked={source.social}
                  onChange={onSourceSocialChange}
                />
                <span className={s.checkText}>Соцсети</span>
              </label>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose}>Отменить</Button>

          <Button onClick={handleOk} disabled={buttonDisabled}>
            Отправить
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={errorShow} onHide={handleErrorClose}>
        <Modal.Header closeButton>
          <Modal.Title>Не выбрано ключевое слово</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Введите ключевое слово, после этого кликните "Добавить"
        </Modal.Body>
      </Modal>
    </div>
  );
};
